$primary-color: #edca8a;
$secondary-color: #e69a0d;
$grey-color: #e4e4e4;

.bg-abk-primary {
  background-color: $primary-color !important;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}
