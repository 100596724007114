#eventView {
  .description {
    text-align: justify;
    min-height: 120px;
  }
}

/* OLD CSS */
.event_picbox {
  text-align: center;

  img {
    border: none;
    margin-left: 20px;
    padding: 5px;
  }
}

.event_author {
  float: right;
  padding: 5px;
  text-align: center;
  margin-left: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 11px;
}

.event_datebox1 {
  border-radius: 6px;
  padding: 3px;
  background: #7ffe7c;
}

.event_datebox2 {
  border-radius: 6px;
  padding: 3px;
  background: #feb97c;
}