/**
* OLD CSS FILE FROM PREVIOUS ABK VERSION
* REFACTORING IS NEEDED
**/

#bodycontainer, .bodycontainer {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  min-height: 500px;
  position: relative;
}

.maincontent {
  margin-right: 340px;
  width: 640px;
  /* android 4.3 fix */
  overflow: hidden;
  margin-bottom: 15px;
}

.maincontentlarge {
  overflow: hidden;
  width: 100%;
  clear: both;
}

/* ========================================================================================== */
#warningcontainer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 30;
  color: #ffffff;
  overflow: hidden;

  a {
    &.button-enter {
      display: inline-block;
      min-width: 100px;
      border-radius: 5px;
      background: #4ddc6e;
      padding: 5px 15px 5px 15px;
      color: #000000;
      font-weight: bold;
      font-size: 16px;
      transition: 0.5s;

      &:hover {
        text-decoration: none;
        color: #ffffff;
      }
    }

    &.button-exit {
      display: inline-block;
      min-width: 100px;
      border-radius: 5px;
      background: #d04465;
      padding: 5px 15px 5px 15px;
      color: #000000;
      font-weight: bold;
      font-size: 16px;
      transition: 0.5s;

      &:hover {
        text-decoration: none;
        color: #ffffff;
      }
    }

    &.button {
      padding: 5px 10px;
      border: 1px solid #444;
      outline: 1px solid #000;
      background: #222;
      font-weight: bold;
      font-size: 14px;
      text-shadow: #000 2px 2px 0;
      text-decoration: none;
      color: #ddd;
      cursor: pointer;

      &:hover {
        background: #444;
      }
    }
  }
}

/* ========================================================================================== */

#infocontainer {
  position: relative;
  clear: both;
  padding-top: 15px;
  margin-top: 10px;
  background: url(/images/main/back1.jpg);
  background-size: cover;
  box-shadow: 0px 0px 3px #aaaaaa;
  z-index: 2;

  .title3 {
    color: #555555;
  }
}

.isnewbox {
  font-weight: bold;
  background: #d8f26d;
  padding: 5px;
  border-radius: 3px;
  font-size: 9px;
  font-family: Arial;
}

.isupdatedbox {
  font-weight: bold;
  background: #fed06f;
  padding: 5px;
  border-radius: 3px;
  font-size: 9px;
  font-family: Arial;
}

.gotopbox {
  clear: both;
  text-align: right;
  font-size: 12px;
}

.littlebox {
  background: #aaaaaa;
  color: #ffffff;
  font-size: 11px;
  font-family: arial;
  text-align: center;
  padding: 2px;
  line-height: 12px;
  border-radius: 5px;
  min-width: 30px;
}

.partbox {
  .sep1 {
  }

  .title {
    font-size: 26px;
    color: #777777;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: solid 3px #dddddd;
  }

  h1, h2 {
    font-weight: normal;
    font-size: 22px;
    color: #777777;
    display: block;
    margin-bottom: 10px;
    border-bottom: solid 3px #dddddd;
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 10px;
    color: #333333;
  }
}

.title3 {
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #777777;
  display: block;
  margin-bottom: 5px;
}

.title4 {
  font-size: 12px;
  font-weight: normal;
  color: #666666;
}

.error {
  color: #000000;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  border: solid 1px #9d4244;
  background: #f6898c;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.info {
  color: #000000;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  background: #94fa75;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.infobox {
  border: solid 1px #dfdfdf;
  background: url(/images/main/greencheck.png) no-repeat 5px 5px #b9ebbf;
  padding: 15px;
  padding-left: 60px;
  margin-bottom: 15px;
  min-height: 30px;
}

/* ================================================================================= */

.categlistcontainer {
  clear: left;
  float: left;
  width: 190px;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;

  .categactive {
    background: #d8f26d;
    border-radius: 3px;
    padding-left: 3px;
    margin-bottom: 2px;
  }
}

.categlabel {
}

.categlabelselected {
  font-weight: bold;
}

.categlistcontainer .categinactive {
  padding-left: 3px;
}

/* ================================================================================== */

#navcontainer {
  margin-top: 20px;

  .titlebox {
    margin-bottom: 10px;
  }

  .title {
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #777777;
  }

  .path {
    margin-bottom: 25px;
    color: #777777;

    a {
      &:link, &:visited {
      }
    }
  }

  a.active {
    &:link, &:visited {
    }
  }
}

/* to convert to h1 */

/* =========================================================================== */

.tabcontainer {
  font-family: 'Open Sans', arial, sans-serif;
  font-size: 13px;

  .tabactive {
    height: 25px;
    border-radius: 8px 8px 0 0;
    width: 170px;
    background: url(/images/main/back3.png) repeat-x #f1c774;
    float: left;
    margin-right: 10px;
    text-align: center;
    padding-top: 5px;
  }

  .tabinactive {
    height: 25px;
    border-radius: 8px 8px 0 0;
    width: 170px;
    background: #e7b150 url(/images/main/back5.png) repeat-x;
    float: left;
    margin-right: 10px;
    text-align: center;
    padding-top: 5px;
  }
}

.tabcontainertop, .tabcontainercontent {
}

.tabcontainerbottom {
  clear: both;
  border-radius: 0 4px 4px 4px;
  height: 10px;
  background: #f1c774;
  overflow: hidden;
  margin-bottom: 15px;
}

/* =============================================================================== */

#topcontainer, .topcontainer {
  background: url(/images/main/back1.jpg);
  background-size: cover;
}

#topcontainerbox, .topcontainerbox {
  width: 980px;
  margin-left: auto;
  margin-right: auto;
}

#topcontainer {
  a {
    &:link, &:visited, &:hover, &:active {
    }
  }

  .vertsep {
    border-left: solid 1px #aaaaaa;
  }
}

.topbar {
}

#footercontainer {
  clear: both;
  padding-top: 20px;
  text-align: center;
  font-size: 11px;
  color: #888888;
  padding-bottom: 20px;
}

#usercontainer {
  color: #555555;
  float: right;
  text-align: right;
  padding-top: 8px;
  overflow: hidden;
}

#userlinks {
  float: left;
}

#searchcontainer {
  float: right;
  margin-left: 30px;

  input[type=text], select {
    font-size: 12px;
    background: url(/images/main/icons/magnifier.png) right 3px no-repeat #ffffff;
    border: 1px solid #eeeeee;
    height: 20px;
  }

  input[type=text] {
    padding: 1px;
  }
}

#langcontainer {
  float: right;
  padding-top: 10px;
  margin-left: 30px;
}

/* ================================================================ */

#menucontainer {
  clear: both;
  background: url(/images/main/back5.png) repeat-x;
  font-family: 'Open Sans', arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 0px 3px #aaaaaa;
  z-index: 1;
  position: relative;
}

#menucontainerbox {
  width: 960px;
  padding-left: 80px;
  margin-left: auto;
  margin-right: auto;
  height: 28px;
  z-index: 2;
}

#menucontainer ul {
  z-index: 10;
  margin: 0;
  list-style: none;
  padding: 0;
  padding-left: 10px;

  li {
    float: left;

    &.active {
      background: url(/images/main/back3.png) repeat-x #aec5f4;
    }

    &.pushed {
      background: url(/images/main/back3revert.png) repeat-x;
    }

    a {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 15px;
      padding-right: 15px;
      display: block;

      &:hover {
        background: url(/images/main/back3.png) repeat-x #aec5f4;
      }
    }

    ul {
      display: none;
      margin-top: 30px;
      float: none;
      position: relative;
      background: #00ff00;
      padding: 5px;
      height: 50px;
    }
  }
}

/* ================================================================ */

#usermenucontainer {
  padding: 0;
  margin: 0;
  float: right;
  text-align: left;
  height: 19px;

  ul {
    z-index: 15;
    margin-top: -5px;
  }

  li {
    padding: 5px;
    padding-bottom: 10px;
    font-weight: normal;
    font-size: 12px;
    margin-top: 3px;
  }

  ul li ul li {
    padding: 2px;
  }
}

/* ================================================================ */

.formbox {
  color: #555555;
  padding: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 4px;
  background: #ffe1a1;

  select, optgroup, textarea {
  }

  label {
    color: #000000;
  }

  legend {
    margin-left: -10px;
    font-size: 16px;
    padding-bottom: 5px;

    span {
      padding: 4px;
      background: #ffeece;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
      color: #555555;
      border-radius: 4px;
    }
  }

  fieldset {
    margin-top: 20px;
    padding: 0 10px;
    border: none;
    background: #ffeece;
    border-radius: 4px;
    color: #000000;
  }
}

fieldset.form-group {
  margin-top: 0;
}

.formbox {
  fieldset.submit {
    text-align: center;
    margin-top: 10px;
    background: none;
    border: none;
  }

  dl {
  }

  dt {
    width: 140px;
    float: left;
    clear: left;
    text-align: right;
  }

  dd {
    float: left;
    width: 430px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    padding-bottom: 5px;
  }

  dl {
    &.small {
      dt {
        width: 120px;
      }

      dd {
        width: 150px;
      }
    }

    &.long {
      dt {
        width: 360px;
      }

      dd {
        width: 210px;
      }
    }

    &.large {
      dt {
        width: 300px;
      }

      dd {
        width: 600px;
      }
    }
  }
}

.formboxlite {
  background: #ffe1a1;
  color: #555555;
  overflow: hidden;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;

  textarea {
  }

  legend {
    font-weight: bold;
  }

  fieldset {
    width: 100%;
    border: none;
  }

  dt.short {
    width: 70px;
    float: left;
    clear: left;
    text-align: right;
    font-size: 12px;
  }

  dd.long {
    float: left;
    padding: 0;
    margin: 0;
    margin-left: 10px;
  }

  fieldset.submit {
    text-align: center;
  }
}

.formbutton {
  background-color: #eeeeee;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eeeeee), color-stop(100%, #cccccc));
  background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
  background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
  background-image: linear-gradient(top, #eeeeee, #cccccc);
  border: 1px solid #cccccc;
  border-bottom: 1px solid #999999;
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  padding: 8px 0;
  text-align: center;
  text-shadow: 0 1px 0 #eee;
  min-width: 150px;
  box-shadow: 0px 1px 2px #aaaaaa;
}

/* =============================================================================== */

.sep1 {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  background: $grey-color;
}

.vertsep {
  border-left: solid 1px #d9d9d9;
  margin-left: 8px;
  margin-right: 8px;
}

/* =============================================================================== */

.pagejump {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 20px;

  ul {
    display: inline;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    display: inline;

    &.pagebox, &.pageboxstart, &.pageboxend {
    }

    &.pageboxcurrent {
      background-color: #d8f26d;
      border-radius: 3px;
      color: #333333;
      font-size: 13px;
      font-weight: normal;
      padding: 8px 0;
      text-align: center;
      min-width: 150px;
      padding-left: 4px;
      padding-right: 4px;
    }

    &.pageboxinfo {
      font-weight: bold;
      padding: 5px;
    }

    &.pageboxform {
      margin-left: 30px;
      padding: 4px;
    }
  }

  a {
    background-color: #f0f0f0;
    border-radius: 3px;
    font-size: 13px;
    font-weight: normal;
    padding: 8px 0;
    text-align: center;
    min-width: 150px;
    padding-left: 4px;
    padding-right: 4px;

    &:hover {
      background-color: #dddddd;
    }
  }
}

/* ================================================================ */

.commentbox {
  overflow: hidden;
}

.commentbox_vote {
  float: right;
}

.commentbox_author {
  float: left;
  text-align: center;
  background: #efebe3;
  padding: 5px;
  width: 60px;
  border-radius: 5px;
  font-size: 11px;
  margin-bottom: 5px;
  margin-right: 15px;
}

.commentbox_content {
  margin-left: 90px;
}

.commentbox_contenttitle {
  font-size: 11px;
  color: #888888;
  margin-bottom: 5px;
}

.commentbox_contenttext {
}

/* ================================================================ */

.authorboxsmall {
  float: left;
  width: 70px;
  padding: 5px;
  text-align: center;
  background: #efebe3;
  border-radius: 4px;
  font-size: 10.5px;
  margin-right: 15px;
  overflow: hidden;
}

.authorboxmed {
  float: left;
  padding-bottom: 5px;
  text-align: center;
  border-radius: 4px;
  font-size: 10.5px;
  margin-right: 15px;
  background: #eeeeee;
  overflow: hidden;
  width: 100px;
  min-height: 90px;
}

.authorboxlarge {
  float: left;
  text-align: center;
  background: #efebe3;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 15px;
  width: 210px;
  overflow: hidden;
}

.authorboxsex0 {
  background: #f2f2f2;
}

.authorboxsex1 {
  background: url(/images/main/user_back1.png) no-repeat;
  background-size: 100% 100%;
}

.authorboxsex2 {
  background: url(/images/main/user_back2.png) no-repeat;
  background-size: 100% 100%;
}

.authorboxsex3 {
  background: url(/images/main/user_back3.png) no-repeat;
  background-size: 100% 100%;
}

.authorboxsex4 {
  background: url(/images/main/user_back4.png) no-repeat;
  background-size: 100% 100%;
}

.authorbox_back {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 10px;
  overflow: hidden;
  margin-top: 15px;
}

.authorboxsmall img {
  border: none;
  margin-left: 10px;
  margin-right: 10px;
}

.authorboxmed img {
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

.authorboxlarge {
  img {
    border: none;
  }

  .authorbox_nickname {
    border-radius: 0 0 4px 4px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }
}

.authorbox_pic {
}

/* ================================================================= */

.advertbox_300x250 {
  width: 300px;
  min-height: 250px;
  margin-bottom: 20px;
}

.advertbox_468x60 {
  width: 468px;
  min-height: 60px;
}

.advertbox_title {
  font-size: 11px;
  color: #aaaaaa;
  text-align: center;
}

.advertbox_banner_300x250 {
  width: 300px;
  height: 250px;
}

.advertbox_banner_468x60 {
  width: 468px;
  height: 60px;
}

.advertbox_300x250 img, .advertbox_468x60 img {
  border: none;
}

/* add comment form */

form[name="eventcomment"] {
  legend {
    font-size: 1.2em;
  }

  .authorboxsmall {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  textarea {
    width: 490px;
    height: 70px;
    font-size: 0.8rem;
  }
}

/* OLD STYLE 2014 */

.fullwidth {
  background: url(/images/main2014/backpic1.jpg) no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
  min-height: 600px;
}

.title_big1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.title_med1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.content_box1 {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;

  ul {
    list-style: disc;
    padding-left: 15px;
  }
}

.content_error {
  background: #f0ad4e;
  padding: 10px;
  margin-bottom: 20px;
}

/* ============================================================================================================== */

.form1 {
  background: rgba(120, 120, 120, 0.4);
  border-radius: 12px;
  padding: 15px;
  overflow: hidden;
  padding: 15px;
  margin: 0;

  input {
    &[type=button], &[type=submit] {
      background: #1abc9c;
      border-radius: 8px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
      border: none;
      color: #ffffff;
      font-family: 'Open Sans', arial, sans-serif;
      box-shadow: none;
      cursor: pointer;
    }

    &[type=button]:hover, &[type=submit]:hover {
      background: #37caad;
    }
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  .fieldsetsep {
    overflow: hidden;
    margin-top: 10px;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
  }

  fieldset.submit {
    padding-bottom: 0;
    text-align: center;
  }

  legend {
    font-size: 18px;
    padding-right: 10px;
    border-top: solid #aaaaaa 3px;
    width: 100%;
    padding-bottom: 10px;
  }

  dl {
    clear: both;
    margin: 0;
    padding: 0;
  }

  dt {
    margin: 0;
    padding: 0;
    float: left;
    width: 180px;
    padding-right: 10px;
    text-align: right;
  }

  dd {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
}

/*
.form1 fieldset:last-of-type {
      padding-bottom: 0;
}
*/

/** SF VERSION **/


