/**
 * You can compile this by https://www.sassmeister.com with
 *
 * - dart-sass v1.18.0
 */

.diff-wrapper.diff {
  $bg-color: #fff;
  $text-color: invert($bg-color);

  $bg-color-edit-base: #88e;
  $bg-color-insert-base: #8e8;
  $bg-color-delete-base: #e88;

  // emphasized colors for detailed inline difference
  $bg-color-edit-highlight: mix($bg-color-edit-base, $bg-color, 80%);
  $bg-color-insert-highlight: mix($bg-color-insert-base, $bg-color, 80%);
  $bg-color-delete-highlight: mix($bg-color-delete-base, $bg-color, 80%);

  // colors for operation rows
  $bg-color-edit: mix($bg-color-edit-base, $bg-color, 25%);
  $bg-color-insert: mix($bg-color-insert-base, $bg-color, 25%);
  $bg-color-delete: mix($bg-color-delete-base, $bg-color, 25%);

  $table-head-color: mix($bg-color, $text-color, 65%);
  $table-sidebar-color: mix($bg-color, $text-color, 80%);
  $border-color: $text-color;

  background-color: $bg-color;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid $border-color;
  color: $text-color;
  empty-cells: show;
  font-family: monospace;
  font-size: 13px;
  width: 100%;
  word-break: break-all;

  th {
    font-weight: 700;
  }

  td,
  th {
    border-collapse: separate;
    border: none;
    padding: 1px 2px;
    background: $bg-color;

    a {
      color: #000;
      cursor: inherit;
      pointer-events: none;
    }
  }

  thead th {
    background: $table-head-color;
    border-bottom: 1px solid $border-color;
    padding: 4px;
    text-align: left;
  }

  tbody {
    &.skipped {
      border-top: 1px solid $border-color;

      td,
      th {
        display: none;
      }
    }

    th {
      background: $table-sidebar-color;
      border-right: 1px solid $border-color;
      text-align: right;
      vertical-align: top;
      width: 4em;

      &.sign {
        background: $bg-color;
        border-right: none;
        padding: 1px 0;
        text-align: center;
        width: 1em;

        &.del {
          background: $bg-color-delete;
        }

        &.ins {
          background: $bg-color-insert;
        }
      }
    }
  }

  &.diff-html {
    white-space: pre-wrap;

    .change {
      &.change-eq {
        .old,
        .new {
          background: $bg-color;
        }
      }

      .old {
        background: $bg-color-delete;
      }

      .new {
        background: $bg-color-insert;
      }

      ins,
      del {
        font-weight: 700;
        text-decoration: none;
      }

      ins {
        background: $bg-color-insert-highlight;
      }

      del {
        background: $bg-color-delete-highlight;
      }
    }

    .edit-container {
      &:hover {
        box-shadow: 0 0 8px 2px #888;
        position: relative;
        z-index: 5;
      }

      &.edited {
        background: $bg-color-edit;
      }
    }

    .edit {
      display: block;
      outline: none;
    }
  }
}