/** MESSAGERIE **/
#inbox {
  .message {
    border-radius: 5px;
    overflow: hidden;
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #f3f3f3;
    }

    .content .fa {
      color: $primary-color;
    }
  }

  .date {
    display: inline-block;
    float: right;
    font-size: 11px;
    margin-bottom: 5px;
    color: #999999;
  }
}