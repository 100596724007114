$masonry-small: 150px;
$masonry-default: 196px;

#masonryGrid {
  .grid-item {
    width: $masonry-default;
    margin-bottom: 1px;

    img {
      width: $masonry-default;
    }

    &.small, &.small img {
      width: $masonry-small;
    }
  }
}

#galleryPreferences {
  .card-body {
    padding: 0.5rem 0 0;
  }

  #chooseR18 img {

  }
}

#uploadImageContainer {
  .category-hidden {
    &.category-gender, &.category-r18 {
      display: none;
    }
  }
}

.gallery_categlist_container {
}

.gallery_categlist_container .sep1 {
  margin-top: 3px;
  margin-bottom: 1px;
}

.gallery_categlist_item {
  overflow: hidden;
  padding: 1px;
  clear: both;
}

.gallery_categlist_itemname {
  width: 280px;
  float: left;
}

.gallery_categlist_itemupdate {
  width: 130px;
  float: left;
  font-size: 11px;
}

.gallery_categlist_itemtotalpics {
  width: 100px;
  float: left;
  font-size: 11px;
  text-align: right;
}

.gallery_categlist_itemtotalalbums {
  width: 100px;
  float: left;
  font-size: 11px;
  text-align: right;
}

.gallery_categlist_itemname a {
  display: block;
}

.gallery_categlist_item:hover {
  background: #eeeeee;
}

.gallery_categlist_itemactive {
  padding: 2px;
  background: #ffee77;
  font-weight: bold;
}

.gallery_categlist_subcateg {
  margin-left: 20px;
}

.zzgallery_categlist_item:hover {
  background: url(/images/main/titlebar1.jpg) repeat-x bottom left;
}

/* ================================= */

.gallery_folderlist_picscontainer {
  overflow: hidden;
}

.gallery_folderlist_picscontainerlarge {
  overflow: hidden;
}

.gallery_folderlist_pic {
  margin-left: 5px;
  margin-bottom: 5px;
  float: left;
}

.gallery_folderlist_pic img {
  border: none;
}

/* ================================= */
.gallery_container {
  padding: 15px;
  font-size: 12px;
  font-weight: normal;
  color: #000000;
}

div.gallery_descpic {
  padding: 10px;
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  color: #999999;
}

.gallery_piclist {
  margin-top: 10px;
  overflow: hidden;
}

.gallery_piclist2 {
  margin-top: 10px;
  margin-right: 310px;
  overflow: hidden;
}

.gallery_picsrow {
}

.gallery_picsrowmini {
}

.gallery_rowsep {
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #cecece;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 20px;
  background: #45ee77;
}

.gallery_picbox {
  margin-bottom: 2px;
  position: relative;
}

.gallery_picbox img {
  border: none;
}

.gallery_picboxtitle {
  display: none;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  width: 140px;
  height: 40px;
  padding: 5px;
  font-size: 11px;
  overflow: hidden;
}

.gallery_picboxmini {
  height: 85px;
  width: 85px;
  float: left;
  text-align: center;
  padding: 0;
  padding-top: 4px;
}

.gallery_picboxmini img {
  border: none;
  width: 75px;
  height: 75px;
  padding: 0;
  margin: 0;
}

/* =============================================== */

.gallery_folderlist {
  height: 500px;
  overflow-y: scroll;
  padding-right: 10px;
}

.gallery_folderlist_folder {
  margin-bottom: 10px;
}

.gallery_folderlist_subfolder {
  /* background: url(http://static.abkingdom.com/images/main/icons/folder_image.png) no-repeat; */
  height: 20px;
  padding-left: 20px;
  margin-left: 10px;
}

.gallery_folderlist_update {
  font-size: 12px;
  color: #999999;
  float: right;
  text-align: right;
}

/* =============================================== */

.gallery_pagejump_box {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery_folderlist_folderbox {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gallery_folderlist_folderinfobox {
  text-align: right;
  float: right;
  width: 210px;
  padding: 5px;
  color: #999999;
  font-size: 11px;
}

.gallery_folderlist_folderdescbox {
  padding-left: 90px;
}

.gallery_folderlist_folderbox img {
  border: none;
}

.gallery_folder_link {
  margin-top: 10px;
}

/* ================================================  */

.gallery_manage_piclist {
  border-top: solid 1px #cecece;
  padding-top: 10px;
}

.gallery_manage_picbox {
  border-bottom: solid 1px #cecece;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.gallery_manage_picbox label {
  width: 80px;
}

/* ================================================  */

.zgallery_albumlist_container {
  width: 255px;
  height: 550px;
  border: solid 1px #eeeeee;
  float: left;
  text-align: center;
}

.gallery_albumlist_album {
  margin-bottom: 10px;
  overflow: hidden;
}

.gallery_albumlist_albumdesc {
  padding-left: 15px;
  float: left;
  width: 520px;
}

.gallery_albumlist_albumauthor {
  float: right;
  padding: 5px;
  text-align: left;
  margin-left: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 11px;
}

.gallery_albumlist_albumthumbs {
  height: 105px;
  float: left;
  width: 110px;
  zbackground: #00ff00;
  overflow: hidden;
}

.gallery_albumlist_albumthumbs img {
  border: 1px solid #eeeeee;
  padding: 1px;
  background: #ffffff;
}

.zgallery_albumlist_album a {
  display: block;
}

.zgallery_albumlist_album:hover {
  background: #eeeeee;
}

.gallery_albumprev_pic1 {
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
}

.gallery_albumprev_pic2 {
  position: relative;
  top: -30px;
  left: 15px;
  z-index: 2;
}

.gallery_albumprev_pic3 {
  position: relative;
  top: -130px;
  left: 45px;
  z-index: 1;
}

.gallery_albumprev_pic1 img,
.gallery_albumprev_pic2 img,
.gallery_albumprev_pic3 img {
  border: 1px solid #eeeeee;
  padding: 1px;
  background: #ffffff;
}

.gallery_picarrow {
  float: left;
  width: 45px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
}

.gallery_picarrow a:link,
.gallery_picarrow a:visited {
  display: block;
  border-radius: 4px;
}

.gallery_picarrow a:hover {
  background: #666666;
}

.gallery_picback {
  background: #000000;
  padding: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

/* ============================================================================== */

.gallery_blog_imgbox {
  width: 900px;
  text-align: center;
  margin-bottom: 40px;
}

.gallery_blog_imgbox img {
  max-width: 900px;
  max-height: 900px;
}

.gallery_picview_imgbox {
  float: left;
  max-height: 900px;
  width: 840px;
  text-align: center;
  background: url(/images/main/icons/wait.gif) center center no-repeat;

  img#picdisp {
    border: none;
    max-height: 800px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.gallery_picshield {
  background: url(/images/main/transparent.gif);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery_picshield_clickable:hover {
  cursor: pointer;
}

.gallery_profile_background {
  overflow: hidden;
  background-size: 100%;
  padding: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.gallery {
  .galleryPanel {
    border-radius: 5px 5px 5px 5px;
    width: 290px;
    height: 180px;
    overflow: hidden;
    float: left;
    background: #eeeeee;
    margin-right: 15px;
    margin-bottom: 15px;

    .galleryThumbnail {
      width: 290px;
      height: 120px;
      overflow: hidden;
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;

      > a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .galleryPanelTitle {
      padding: 5px;
      text-align: left;
    }

  }

  .galleryPicbox {
    height: 130px;
    background: #eeeeee;
    border-radius: 5px;
    width: 140px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;

    &.pictureWaitingValidation {
      background: #fb9b63;
    }

    &.pictureDenied {
      background: #ef7e7e;
    }

    &.pictureWaitingValidation, &.pictureDenied {
      a {
        color: white;
      }
    }

    .galleryThumbnail {
      border-radius: 5px 5px 0 0;
      width: 140px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      overflow: hidden;

      > a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .title4 {
      padding-top: 3px;
      height: 20px;
      text-align: center;
    }
  }
}

.imageBox {
  text-align: left;
  height: 500px;

  > a.image img {
    width: auto;
    max-height: 500px;
    max-width: 100%;
  }
}

.moderateImage {
  margin-left: 10px;
  vertical-align: top;

  .form-actions {

  }
}

#manageGalleryActions {
  label {
    margin-bottom: 0;
  }

  select {
    margin-bottom: 10px;
    border: solid 1px #cccccc;
    background: #ffffff;
  }
}
