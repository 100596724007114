#userHeader {
  .background {
    background-color: #efebe3;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 15px;
    margin-bottom: 15px;
  }

  .statusBadge {
    margin-right: 15px;
    border-radius: 5px;
    float: right;
    padding: 5px;
    font-weight: bold;
    color: #ffffff;

    &.online {
      background: rgba(33, 174, 0, 0.8);
    }

    &.blockWarning {
      background: rgba(229, 86, 86, 0.8);
    }

    &.inactiveWarning {
      background: rgba(227, 134, 0, 0.8);
    }
  }

  .quote {
    margin-bottom: 10px;
    color: #000000;
  }

  .authorbox_back > div.sep {
    float: right;
    text-align: left;
    width: 150px;
  }

  .premiumBadge {
    float: left;
    padding-right: 20px;

    img {
      border: none;
    }
  }

  .tags {
    margin-top: 5px;
  }
}

#userTabs .littlebox {
  float: right;
  margin-right: 5px;
  margin-left: 0;
}

#userSidebar .infoLabel {
  clear: both;
  float: left;
  width: 140px;
}