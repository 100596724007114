.tipscontainer {
  float: right;
  width: 300px;
  border-radius: 5px;

  .tipstitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    color: #999999;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: solid 3px #dddddd;
  }

  .tipssubtitle {
    margin-top: 20px;
    font-weight: bold;
  }

  .tipscontent {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    border-radius: 5px 5px 5px 5px;
  }

  .tipscontent2 {
    margin-bottom: 20px;
    background: #f0f0f0;
    border-radius: 4px;
    padding: 10px;

    h2 {
      font-size: 1.7em;
    }
  }
}

.tipscontent .sep1 {
}

.tipscontent2 .sep1 {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  background: #d9d7ce;
}

.tipscontainer2 {
  float: left;
  width: 200px;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 20px;

  .tipscontent2 {
    background: #f0f0f0;
    border-radius: 4px;
    padding: 10px;
  }
}
