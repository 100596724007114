#bugReport {
  position: fixed;
  z-index: 9999;

  .btn-circle.btn-lg {
    width: 150px;
    height: 40px;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 1.33;
    border-radius: 25px;

    .text {
      vertical-align: super;
    }
  }

  textarea {
    height: 180px;
  }

  .screenshot {
    position: relative;
    top: -24px;
    right: 10px;
    opacity: .6
  }

  .screenshot:hover {
    opacity: 1
  }

  .reported p, .failed p {
    height: 190px
  }


  &.left {
    left: 15px;
    bottom: 15px
  }

  &.right {
    right: 5px;
    bottom: 15px
  }

  .dropdown-menu {
    width: 290px;
    height: 320px;
    bottom: 50px;
  }

  &.left .dropdown-menu {
    left: 0px
  }

  &.right .dropdown-menu {
    right: 0px
  }

  .hideme {
    display: none
  }
}