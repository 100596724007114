.classified_container {
  overflow: hidden;
}

.classified_infobox {
  float: right;
  width: 100px;
  text-align: center;
  margin-left: 20px;
}

.classified_contentbox {
  min-height: 110px;
  text-align: justify;
}

.classified_contentboxtitle {
  font-size: 18px;
  font-weight: bold;
  color: #0ca5b0;
  padding-bottom: 5px;
}

.classified_contentboxinfo {
  color: #777777;
}

.classified_contentboxpicthumb {
  margin-bottom: 10px;
  text-align: center;
  margin-right: 10px;
  font-size: 11px;
  color: #999999;
}

.classified_contentboxpicthumb img {
  padding: 4px;
  border: 1px solid #eeeeee;
  max-width: 920px;
}

.classified_author {
  float: right;
  padding: 5px;
  text-align: left;
  margin-left: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 11px;
}