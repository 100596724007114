#vueMapApp {
  #map {
    margin-bottom: 20px;
  }

  #users {
    li.media {
      &:not(:first-child) {
        padding-top: 10px;
      }

      &:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid $grey-color;
      }

      img {
        width: 70px;
      }
    }
  }
}