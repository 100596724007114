.product_listsell_container {
  padding-bottom: 5px;
  background: url(/images/main/back_box10line.png) left top repeat-y;
  max-height:  200px;
  overflow-y: scroll;
}

.product_listsell_titlecontainer {
  min-height: 22px;
  background: url(/images/main/back_title10.png) left top no-repeat #7f8da6;
  width: 640px;
  padding: 0;
  margin: 0;
  padding-top: 3px;
  padding-left: 10px;
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
}

.product_item_desc {
  text-align: justify;
}

.product_item_descpicbox {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}

.product_item_descpicbox.firstpic {
  float: none;
}

.product_item_descpicbox img {
  border: none;
  max-width: 300px;
  max-height: 300px;
}

.product_item_comments {

}

.product_item_comment {
  min-height: 50px;
  text-align: justify;
  overflow: hidden;
}

.product_item_comment_text {
  margin-left: 65px;
  margin-top: 10px;
}

.product_item_comment_pics {
  float: left;
  margin-bottom: 10px;
  width: 310px;
}

.product_item_comment_descpicbox {
  float: left;
  margin-right: 20px;
}

.product_item_comment_descpicbox img {
  border: none;
  max-width: 150px;
  max-height: 150px;
}

.product_listbrandsell_container {
  max-height: 400px;
  overflow-y: scroll;
}
