.forum_topiclist {
  .authorboxsmall {
    margin-right: 10px;
  }
}

/* OLD CSS */
/* == FORUM LIST CSS ========================================= */
ins {
  color: #48df44;
}

del {
  color: #df4477;
}

.forum_forumlist {
}

.zzforum_forumlistcont1 {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  background: #efebe3;
  border-left: solid 10px #d9d7ce;
  padding-left: 15px;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  overflow: hidden;
  color: #555555;
  font-family: Trebuchet MS, Arial, sans-serif;
  font-size: 17px;
  font-weight: bold;
}

.forum_forumlistcont1 {
  font-family: 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  font-weight: normal;
  font-size: 22px;
  color: #777777;
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  border-bottom: solid 3px #dddddd;
  overflow: hidden;
  padding-left: 15px;
}

.forum_forumlistcont2 {
  clear: both;
  padding-left: 10px;
  overflow: hidden;
}

.forum_forumlistcont3 {
  text-align: left;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  clear: both;
  background: #efefef;
}

.forum_forumlistcont4 {
  padding-bottom: 5px;
}

.forum_forumlistcont5 {
  padding-bottom: 10px;
}

.forum_forumlistcont6 {
  min-height: 20px;
  padding-top: 3px;
  padding-left: 20px;
}

.forum_forumlistcont7 {
  margin-bottom: 20px;
}

.forum_forumlistcol1 {
  width: 30px;
  float: left;
}

.forum_forumlistcol2 {
  width: 300px;
  float: left;
  text-align: left;
}

.forum_forumlistcol3 {
  width: 290px;
  float: left;
  text-align: left;
  padding-top: 5px;
  font-size: 16px;
  margin-left: 15px;
}

.forum_forumlistcol4 {
  width: 270px;
  text-align: left;
  float: left;
  padding-top: 5px;
  font-size: 16px;
  margin-left: 30px;
}

.forum_forumlistcoltitle, .forum_forumlistcoltitle2, .forum_forumlistcategtitle {
}

.forum_forumlisttopictitle {
  font-size: 16px;
}

.forum_forumlisttopicdesc {
  margin-bottom: 10px;
}

.forum_forumlisttopictotalmsg {
  font-size: 11px;
  font-weight: normal;
  line-height: 14px;
  text-align: right;
}

.forum_forumlisttopiclastmsg {
  font-size: 11px;
  font-weight: normal;
  line-height: 14px;
  text-align: left;
}

.forum_forumlisttopicpopular {
  font-size: 11px;
  font-weight: normal;
}

.forum_forumlistsep {
  background-color: #6483f6;
  height: 1px;
}

.forum_item {
  width: 16px;
  border-radius: 8px;
  height: 65px;
  background: #eeeeee;

  &.unread {
    background: #afd900;
  }
}

/* == TOPICS LIST CSS ======================================================================= */

.forum_topiclist .sep1 {
  margin: 0;
  padding: 0;
}

.forum_topic_item {
  font-family: 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  font-weight: normal;
  display: block;
  padding: 0;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  clear: both;
  overflow: hidden;
  padding-left: 15px;

  &.header {
    font-size: 22px;
  }
}

.forum_topiclist > .forum_topic_item:first-child {
  margin-bottom: 10px;
  border-bottom: solid 3px #dddddd;
  color: #999999;
}

.forum_topic_item {
  &.topic_sticky {
    background: #fff5bd;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.topic_hidden {
    background: #f7b1b1;
    border-bottom: solid 1px #dedede;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.topic_other {
    color: #aaaaaa;
    background: #aaaaaa;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: line-through;
  }

  .topic_column {
    float: left;
    width: 16px;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    margin-right: 20px;
  }
}

.topic_indicator {
  min-height: 65px;
  background-color: #eeeeee;
  background-repeat: no-repeat;
  background-position: left top;
}

.topic_sticky .topic_indicator {
  background: #d9bb80;
}

.topic_hidden .topic_indicator {
  background-image: url(/images/main/icons/exclamation.png);
}

.topic_locked .topic_indicator {
  background-image: url(/images/forum/icon_topicclosed.gif);
}

.topic_new_topic .topic_indicator {
  background: #afd900;
}

.topic_new_message .topic_indicator {
  background: #e4f796;
}

.forum_topiclist_img2 {
  background: url(/images/forum/icon_hottopicnew.gif) no-repeat left top;
}

.forum_topiclist_img5 {
  background: url(/images/forum/icon_hottopicnonew2.gif) no-repeat left top;
}

.forum_topiclist_img7 {
  background: url(/images/forum/icon_hottopicnewsemi.gif) no-repeat left top;
}

.forum_topiclistcol2 {
  text-align: left;
  float: left;
  width: 560px;
}

.forum_topiclistcol3 {
  width: 160px;
  display: none;
  color: #999999;
  min-height: 60px;
  float: left;
  margin-left: 15px;
}

.forum_topiclistcol4 {
  width: 160px;
  margin-left: 15px;
  float: left;
  font-size: 18px;
}

.forum_topiclistcol5 {
  width: 160px;
  float: left;
  margin-left: 15px;
  font-size: 18px;
}

.zzforum_topic_listtopic_title {
  font-weight: bold;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-bottom: 5px;
}

.forum_topic_listtopic_title {
  font-family: 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 5px;
}

.forum_topic_listtopic_msg {
  padding-right: 40px;
}

.forum_topic_listtopic_msgcontent {
  line-height: 1.4em;
  font-size: 11px;
}

.forum_topic_listtopic_msgjump {
  margin-top: 8px;
}

.zzforum_topic_listtopic_lastmsg {
  font-size: 11px;
  font-weight: normal;
  color: #999999;
  text-align: left;
  line-height: 14px;
  margin-left: 10px;
}

.forum_topic_listtopic_lastmsg {
  font-size: 11px;
  font-weight: normal;
  color: #999999;
  text-align: left;
  line-height: 12px;
  padding-top: 5px;
}

.forum_topic_listtopic_author {
  text-align: left;
  overflow: hidden;
  padding: 5px;
  background: #f2f2f2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.forum_topic_listtopic_date {
  font-size: 11px;
  line-height: 14px;
}

.forum_topic_listtopic_views {
}

/* ============================================================================== */

.forum_topic_jump {
  ul {
    background: url(/images/main/icons/page_white_copy.png) center left no-repeat;
    margin: 0;
    padding: 0;
    display: inline;
    padding-left: 20px;
  }

  li {
    list-style-type: none;
    display: inline;

    &.pagebox {
      font-weight: bold;
    }

    &.pageboxstart, &.pageboxend, &.pageboxcurrent, &.pageboxinfo {
    }
  }

  a {
    padding: 3px;
    text-decoration: none;
    background: #eeeeee;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;

    &:hover {
      background: #ebf4fd;
    }
  }
}

/* === NEW MESSAGE FORM CSS ======================================== */

.forum_newmsgtitleinput {
  width: 500px;
}

.forum_newmsgoriginput {
  width: 500px;
  height: 60px;
}

.forum_newmsgbodyinput {
  width: 500px;
  height: 300px;
}

.forum_newmsgreply {
  width: 500px;
  height: 70px;
}

.forum_searchinput {
  width: 350px;
}

/* ==== MESSAGE CSS ======================================= */

.forum_messagelist {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.forum_msg_msgread, .forum_msg_msgunread {
  padding-top: 10px;
}

.forum_msg_msghidden {
  background: #f7b1b1;
  padding-top: 10px;
}

.forum_msg_msgdeleted {
  background: url(/images/forum/hash.png);
  padding-top: 10px;
}

.forum_msgheader {
  height: 20px;
  padding: 4px;
  color: #777777;
}

.forum_msgheaderunread {
  height: 20px;
  padding: 4px;
  color: #7973CF;
}

.forum_msg_content {
  overflow: hidden;
  margin-top: 5px;
}

.forum_msg_content_hidden {
  height: 30px;
  overflow: hidden;
}

.forum_msg_title {
  float: left;
  padding-left: 15px;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.forum_msg_date {
  height: 5px;
  background: #f2f2f2;
  border-radius: 4px;
  font-size: 11px;
  font-weight: normal;
  color: #999999;
  margin-bottom: 30px;
}

.forum_msg_date_new {
  height: 5px;
  background: #d8f26d;
  border-radius: 4px;
  font-size: 11px;
  font-weight: normal;
  color: #97b12c;
  margin-bottom: 30px;
}

.forum_msg_opt {
  float: right;
  margin-bottom: 5px;
  margin-top: -100px;
  background: #efebe3;
  border-radius: 5px 5px 5px 5px;

  ul {
    margin: 0;
    padding: 5px;

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
}

.forum_msg_opt_std {
}

.forum_msg_opt_report a {
  background: url(/images/main/icons/report.gif) no-repeat;
  display: block;

  &:hover {
    background: url(/images/main/icons/reporthover.gif) no-repeat;
  }
}

.forum_msg_opt_up {
  min-width: 30px;

  a {
    background: url(/images/main/icons/like.png) no-repeat;
    padding-left: 20px;
    text-decoration: none;

    &:hover {
      background: url(/images/main/icons/likegreen.png) no-repeat;
    }
  }
}

.forum_msg_opt_upon {
  min-width: 30px;

  a {
    background: url(/images/main/icons/likegreen.png) no-repeat;
    padding-left: 20px;

    &:hover {
      background: url(/images/main/icons/like.png) no-repeat;
    }
  }
}

.forum_msg_opt_upinfo {
  min-width: 35px;

  span {
    background: url(/images/main/icons/handup.gif) no-repeat;
    padding-left: 16px;
    margin-left: 4px;
  }
}

.forum_msg_opt_down {
  min-width: 30px;

  a {
    background: url(/images/main/icons/likenot.png) no-repeat;
    padding-left: 20px;
    text-decoration: none;

    &:hover {
      background: url(/images/main/icons/likenotred.png) no-repeat;
    }
  }
}

.forum_msg_opt_downon {
  min-width: 35px;

  a {
    background: url(/images/main/icons/likenotred.png) no-repeat;
    padding-left: 20px;
    text-decoration: none;

    &:hover {
      background: url(/images/main/icons/likenot.png) no-repeat;
    }
  }
}

.forum_msg_opt_downinfo {
  min-width: 35px;

  span {
    background: url(/images/main/icons/handdown.gif) no-repeat;
    padding-left: 16px;
    margin-left: 4px;
  }
}

.forum_msg_user {
  float: left;
  width: 90px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 11px;
}

.forum_msg_usernick {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin-bottom: 5px;
}

.forum_msg_userlevel {
  padding-top: 4px;
}

.forum_msg_userpic {
}

.forum_msg_userinfo {
  font-size: 11px;
  line-height: 15px;
  font-weight: normal;
  color: #999999;
  clear: both;
  text-align: center;
}

.forum_msg_text {
  position: relative;
  margin-left: 160px;
  margin-bottom: 10px;
  text-align: justify;
  line-height: 19px;
  max-width: 600px;
  min-height: 120px;
  font-size: 13px;
  z-index: 1;
}

.forum_msg_filesbox {
  width: 920px;
}

.forum_msg_file {
  margin-bottom: 10px;
  font-size: 11px;
  color: #999999;
  text-align: center;
  width: 150px;
  overflow: auto;
}

.forum_msg_picbox {
  font-size: 11px;
  color: #999999;
  text-align: center;

  img {
    padding: 4px;
    border: 1px solid #eeeeee;
  }
}

.forum_msg_reply {
  background: url(/forum/images/icon_write.gif) no-repeat;
  padding-left: 20px;
}

.forum_msg_quote {
  border-radius: 4px;
  background: #f2f2f2;
  padding: 10px;
  color: #777777;

  .forum_msg_quote {
    border: 2px solid #cccccc;
  }
}

.forum_msg_editinfo {
  margin-top: 15px;
  font-size: 11px;
  color: #999999;
  padding: 5px;
  border: 1px solid #cecece;
}

.forum_msg_warn {
}

.forum_msg_warntitle {
  font-weight: bold;
}

.forum_modbox {
  background: #ead068;
  margin-top: 20px;
  padding: 10px;
  padding-top: 4px;
  border-radius: 4px;
  font-size: 11px;
  font-family: arial;
  height: 25px;
  overflow: hidden;
  clear: both;

  > a.toggleModBox {
    cursor: pointer;
  }

  &.display {
    height: auto;
  }
}

.forum_evalbox {
  visibility: hidden;
  display: none;
  border: solid 1px #aaaaaa;
  background: #feffcf;
  padding: 10px;
  width: 150px;
  position: absolute;
  margin-left: -180px;
  margin-top: 20px;
  z-index: 2;
  font-size: 11px;
  font-family: Arial;
}

.forum_evaldetailbox {
  visibility: hidden;
  display: none;
  padding: 15px;
  width: 150px;
  background: url(/images/main/dropdown_pane.png) left bottom no-repeat;
  position: absolute;
  color: #000000;
  margin-left: 140px;
  margin-top: 16px;
  font-size: 11px;
  font-family: Arial;
}

.forum_msg_tumblrpost {
  margin-top: 15px;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 15px;

  ol.tumblr_posts {
    list-style-type: none;
  }

  .tumblr_caption p {
    margin-top: 15px;
  }

  .html_photoset {
    text-align: center;
  }
}

.forum_msg_youtubepost {
  margin-top: 15px;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
}

.forum_msg_youtubevideo {
  padding-top: 15px;
}

.forum_msg_iframepost {
  margin-top: 15px;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 15px;

  iframe {
    overflow: hidden;
  }
}