.partbox.sorting {
  a.active {
    font-weight: bold;
    color: #000;
  }
}

.lib_textbox {
  border-radius: 5px;
  background: #f6f1df;
  text-align: justify;
  line-height: 1.5em;
  font-family: "Lusitana", serif;
  font-size: 16px;
  padding: 60px 90px;
}

.lib_textbox hr {
  color: #000000;
  background-color: #000000;
  height: 2px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lib_title {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: dotted 1px #000000;
}

.lib_categtitle {
  font-weight: bold;
}

.lib_categlevel0 {
  margin-left: 0px;
  margin-bottom: 10px;
}

.lib_categlevel0 .lib_categtitle {
  font-size: 14px;
}

.lib_categlevel1 {
  margin-left: 20px;
  margin-bottom: 10px;
}

.lib_categlevel1 .lib_categtitle {
  font-size: 14px;
}

.lib_categlevel2 {
  margin-left: 40px;
  margin-bottom: 10px;
}

.lib_categlevel2 .lib_categtitle {
  font-size: 14px;
}

.lib_categlevel3 {
  font-size: 12px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.lib_doclist_docauthor {
  float: right;
  padding: 5px;
  width: 70px;
  text-align: center;
  margin-left: 15px;
  background: #f2f2f2;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 11px;
}

.lib_bookstore_box {
  background: url(/images/main/back_wood.jpg) repeat-y;
  padding-top: 60px;
  padding-left: 65px;
  overflow: hidden;

  .bookstore_row {
    overflow: hidden;
    margin-bottom: 39px;
    width: 500px;
  }
}

.lib_bookstore_bookcover {
  width: 90px;
  height: 120px;
  background: url(/images/main/back_book.jpg) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 8px;
  float: left;
  margin-right: 15px;
  box-shadow: 0 0 8px #333333;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.lib_bookstore_bookcover a,
.lib_bookstore_bookcover a:visited {
  color: #111111;
}

.lib_bookstore_bookcover a:hover {
  text-decoration: none;
}

.lib_bookstore_booktitle {
  padding-top: 15px;
  text-shadow: 0 0 4px #aaaaaa;
  color: #111111;
  line-height: 11px;
  font-size: 11px;
  font-weight: bold;
  font-family: arial;
}

.lib_bookstore_bookauthor {
  border-top: solid 1px #111111;
  position: absolute;
  text-shadow: 0 0 4px #aaaaaa;
  top: 100px;
  left: 15px;
  width: 80px;
  font-weight: normal;
  font-size: 11px;
  font-family: arial;
}