.faq_articlelist {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 2px;
  }
}

.faq_article {
  line-height: 1.5em;
  text-align: justify;
  zfont-family: Verdana, Arial;
  .title3 {
    line-height: 25px;
  }
}