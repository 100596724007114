body {
  background: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 12.5px;
  color: #222222;
}

.quicksand {
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;
}

a {
  &:not(.btn) {
    &:link, &:visited {
      text-decoration: none;
      color: #427fed;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

[v-cloak] {
  display: none !important;
}

.btn-xs {
  @include button-size(.25rem, .4rem, .875rem, .5, .2rem);
}


form .form-actions {
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.form-control {
  font-size: 0.8rem;
}

a {
  &.subject {
    display: block;
    color: #222222;
    text-decoration: none;
  }

  &.disabled {
    color: gray;
    pointer-events: none;
  }
}

.sidebarLoginForm {
  .form-group {
    margin-bottom: 5px;
  }
}

img.star-rating {
  vertical-align: initial;
}

.pointer {
  cursor: pointer;
}

.title {
  font-size: 26px;
  color: #777777;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: solid 3px #dddddd;
}

.global-information {
  width: 100%;
  padding: 3px 10px;
  background-color: #f0d5a5;
  color: #000;

  &.warning {
    background-color: orange;
  }

  &.admin {
    padding: 5px 10px;
    border-bottom: 1px solid #daba8a;

  .admin-title {
      color: #4c4c4c;
      font-weight: bold;
    }

    .vertsep {
      border-left: solid 1px #929292;
    }
  }
}

.modal .maincontent {
  width: auto !important;
}

.custom-file-label {
  overflow: hidden;
}
