#support {
  .ticket {
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 10px;
    background: #b9b9b9;

    &.status0 {
      background: #ffa600;
    }

    &.status1, &.status4 {
      background: #6dff9d;
    }
  }
}