.blog_container {
  margin-bottom: 20px;
  border: solid 1px #eeeeee;
  padding: 20px;
  border-radius: 16px;

  .blog_content {
    text-align: justify;
    line-height: 20px;
    font-size: 14px;

    .blog_article_picbox {
      img {
        width: 100%;
      }
    }
  }
}

/* OLD CSS */

.news_box {
  border-radius: 6px;
  margin-bottom: 15px;
  border: solid 1px #f2f2f2;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  a {
    color: #ffffff;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }
}

.news_titlebox1 {
  background: #606060;
  width: 100%;
  font-size: 20px;
  padding: 10px;
  transition: 0.5s;

  &:hover {
    background: #8f8f8f;
  }
}

.news_titlebox2 {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  font-size: 20px;
  padding: 10px;
  overflow: hidden;
  transition: 0.5s;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
}

.news_backbox1 {
  width: 100%;
  background: #666666;
  overflow: hidden;
}

.news_backbox2 {
  width: 100%;
  height: 200px;
  position: relative;
}

.news_subtitlebox {
  font-size: 15px;
  color: #aaaaaa;
  margin-top: 5px;
}
